import React from "react"
import Layout from '../components/Layout'
import Helmet from "react-helmet"
import {I18nextContext, useTranslation} from 'gatsby-plugin-react-i18next'
import { useSiteMetadata } from "../hooks/use-site-metadata"
import favicon from "../../static/favicon.svg"

export default function Home() {
  const { t } = useTranslation("Index");
  const { title } = useSiteMetadata();
  const { language } = React.useContext(I18nextContext);
  return (
    <Layout>
      <Helmet>
        <html lang={language}/>
        <link rel="icon" href={favicon}/>
        <title>{t(title)}</title>
        <meta name="title" content={title}/>
        <meta name="og:title" content={title}/>
      </Helmet>
      <article className={"articulo container-principal index text-justify"}>
        <h1>{t("404_fault")}</h1>
      </article>
    </Layout>
  )
}
